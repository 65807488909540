import React , { useState,useEffect }from "react";
import axios from 'axios';
import useStateRef from "react-usestateref";
import { Grid, Paper, Container } from "@mui/material";
import Sidebar from "./Sidebar";
import { Link,useNavigate } from "react-router-dom";
import { env } from "../envconfig";
import { toast } from "react-toastify";

function Chat() {

  const [activeButton, setActiveButton] = useState(1);
  const BACKEND_URL = env.BACKEND_URL;
  const navigate = useNavigate();

  const [cardPlan,setCardPlan,cardPlanref] = useStateRef("");
  const [token, setToken, Tokenref] = useStateRef("");

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };

  useEffect(() => {
    setToken(localStorage.getItem("user_token"));
    getPlan();
  }, [])

  const getPlan = async() => {
    try {
      const response = await axios.get(`${BACKEND_URL}/user/getPlan`,{
        headers: {
          Authorization: `${Tokenref.current}`,
        },
      });
      console.log(response.data.details,"response data");
      setCardPlan(response.data.details);
    } catch (error) {
      
    }
  }
  

  const handleSubmit = async(payload) =>{
    if(payload === 1){
      console.log(payload," free card");
      const cardDetail = {
        card : "Free subscription"
      };
      const response = await axios.post(`${BACKEND_URL}/user/subscription`,cardDetail,{
        headers: {
          Authorization: `${Tokenref.current}`,
        },
      });
      console.log(response.data,"response free card");
      if(response.data.status == true){
        toast.success(response.data.Message);
        navigate("/chathome");
      }else{
        toast.error(response.data.Message);
      }
    }else if (payload === 2){
      console.log(payload," Standard card");
      const cardDetail = {
        card : "Standard subscription"
      };
      const response = await axios.post(`${BACKEND_URL}/user/subscription`,cardDetail,{
        headers: {
          Authorization: `${Tokenref.current}`,
        },
      });
      console.log(response.data,"response free card");
      if(response.data.status == true){
        toast.success(response.data.Message);
        navigate("/chathome");
      }else{
        toast.error(response.data.Message);
      }

    } else {
      console.log(payload," Premium card");
      const cardDetail = {
        card : "Premium subscription"
      };
      const response = await axios.post(`${BACKEND_URL}/user/subscription`,cardDetail,{
        headers: {
          Authorization: `${Tokenref.current}`,
        },
      });
      console.log(response.data,"response free card");
      if(response.data.status == true){
        toast.success(response.data.Message);
        navigate("/chathome");
      }else{
        toast.error(response.data.Message);
      }

    }
  };


  return (
        <div>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={12} sm={12} md={8} lg={2} xl={2}>
                  <Sidebar />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                <div className="container">
                  <div className="text-center">
                    <div className="chat-homehead">
                      <div className="chat-homehead-one">
                        <span className="chat-homehello"> Subscribe</span>
                        <span className="chat-homesubhead"> Try our premium plans!</span>
                      </div>
                      {/* <div className="chat-homehead-two">
                        <div className={activeButton === 1 ? 'chat-homehead-firstbtn' : 'chat-homehead-secondbtn'} onClick={() => handleButtonClick(1)}>
                          <div className="menu_items menu_items_fex">
                            <div className="navlink_new">
                            <span> Weekly</span> 
                            </div>
                          </div>
                        </div>
                        <div className={activeButton === 2 ? 'chat-homehead-firstbtn' : 'chat-homehead-secondbtn'} onClick={() => handleButtonClick(2)}>
                        <div className="menu_items menu_items_fex_chat">
                          <div className="navlink_new">
                          <span>Yearly</span> 
                          </div>
                        </div>
                        </div>
                      </div> */}
                    </div>

                      <div>
                         <div className="row mx-3 mb-3">
                      <div className="col-lg-4">
                        <div className={cardPlanref.current == "Free subscription" ? "subscribe-firstcard hover" : "subscribe-firstcard"}>
                            <div className="subscribe-innercard-top">
                            <div className="subscribe-inercard-first">
                                <div className="subscribe-inercard-firstline">
                                    <span className="subscribe-inercard-firstline-left">
                                      Free
                                    </span>
                                </div>
                                <span className="subscribe-inercard-secondline">
                                 For newcomers exploring, with limited features
                                </span>
                                <div className="subscribe-inercard-thirdline">
                                    <div className="d-flex">
                                        <span className="subscribe-offer-bold">$0</span>
                                        <div className="subscribe-offer-main">
                                            <span className="subscribe-week">/Monthly</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="subscribe-inercard-second" onClick={() => handleSubmit(1)}>
                                <span className="subscribe-btn"> Subscribe</span>
                            </div>
                            </div>
                            <hr className="subscribe-horizon" /> 
                            <div className="subscribe-innercard-bottom">
                                <span className="subscribe-bottom-head" >What’s Included</span>
                                <div className="subscribe-innercard-bottomsecond">
                                   <div className="subscribe-bottom-items">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-bottom-signal" viewBox="0 0 40 40" id="tick"><path d="M15.48 28.62a1 1 0 0 1-.71-.29l-7.54-7.54a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l6.83 6.83L32.12 9.57a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42L16.18 28.33a1 1 0 0 1-.7.29Z" fill="#28b446" class="color000000 svgShape"></path></svg>
                                    <span className="subscribe-bottom-itemname"> Unlimited messaging, interactions, past chats </span>
                                    </div>
                                    <div className="subscribe-bottom-items">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-bottom-signal" viewBox="0 0 40 40" id="tick"><path d="M15.48 28.62a1 1 0 0 1-.71-.29l-7.54-7.54a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l6.83 6.83L32.12 9.57a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42L16.18 28.33a1 1 0 0 1-.7.29Z" fill="#28b446" class="color000000 svgShape"></path></svg>
                                    <span className="subscribe-bottom-itemname">Available on Web, iOS, and Android platforms.</span>
                                    </div>
                                    <div className="subscribe-bottom-items">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-bottom-signalwrong" viewBox="0 0 50 50" id="Close"><path d="M2.707 48.707 25 26.414l22.293 22.293 1.414-1.414L26.414 25 48.707 2.707l-1.414-1.414L25 23.586 2.707 1.293 1.293 2.707 23.586 25 1.293 47.293z" fill="#28b446" class="color000000 svgShape"></path></svg>
                                    <span className="subscribe-bottom-itemnameno"> Updated data</span>
                                    </div>
                                    <div className="subscribe-bottom-items">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-bottom-signalwrong" viewBox="0 0 50 50" id="Close"><path d="M2.707 48.707 25 26.414l22.293 22.293 1.414-1.414L26.414 25 48.707 2.707l-1.414-1.414L25 23.586 2.707 1.293 1.293 2.707 23.586 25 1.293 47.293z" fill="#28b446" class="color000000 svgShape"></path></svg>
                                    <span className="subscribe-bottom-itemnameno"> Image generation</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className={cardPlanref.current == "Standard subscription" ? "subscribe-firstcard hover" : "subscribe-firstcard"}>
                        <div className="subscribe-innercard-top">
                            <div className="subscribe-inercard-first">
                                <div className="subscribe-inercard-firstline">
                                    <span className="subscribe-inercard-firstline-left">
                                      Standard
                                    </span>
                                    <div className="subscribe-inercard-firstline-right">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-offer-top" viewBox="0 0 64 64" id="Thunder"><defs><clipPath id="a"><rect width="64" height="64" fill="#a36aff" class="color000000 svgShape"></rect></clipPath></defs><g clip-path="url(#a)" fill="#a36aff" class="color000000 svgShape"><path d=" M 37.096 2 L 27.328 2 C 25.948 2 24.387 3.03 23.843 4.298 L 11.514 33.066 C 10.711 34.939 11.714 36.46 13.751 36.46 L 30.981 36.46 C 33.019 36.46 34.15 38.029 33.506 39.962 L 27.316 58.532 C 25.943 62.652 26.943 63.176 29.549 59.702 L 52.149 29.568 C 53.372 27.938 52.71 26.614 50.673 26.614 L 33.443 26.614 C 31.405 26.614 30.365 25.078 31.122 23.186 L 38.668 4.321 C 39.18 3.04 38.476 2 37.096 2 Z " fill="#a36aff" class="color000000 svgShape"></path></g></svg>
                                      <span className="subscribe-offer-stable"> Hot </span>
                                    </div>
                                </div>
                                <span className="subscribe-inercard-secondline">
                                With the Standard, you gain access to continually updated data.
                                </span>
                                <div className="subscribe-inercard-thirdline">
                                    <div className="d-flex align-items-end ">
                                        <span className="subscribe-offer-bold">$15</span>
                                        <div className="subscribe-offer-main">
                                            <span className="subscribe-week">/Monthly</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="subscribe-inercard-second" onClick={() => handleSubmit(2)}>
                                <span className="subscribe-btn"> Subscribe</span>
                            </div>
                            </div>
                            <hr className="subscribe-horizon" /> 
                            <div className="subscribe-innercard-bottom">
                                <span className="subscribe-bottom-head" >What’s Included</span>
                                <div className="subscribe-innercard-bottomsecond">
                                    <div className="subscribe-bottom-items">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-bottom-signal" viewBox="0 0 40 40" id="tick"><path d="M15.48 28.62a1 1 0 0 1-.71-.29l-7.54-7.54a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l6.83 6.83L32.12 9.57a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42L16.18 28.33a1 1 0 0 1-.7.29Z" fill="#28b446" class="color000000 svgShape"></path></svg>
                                    <span className="subscribe-bottom-itemname"> Unlimited messaging, interactions and past chats</span>
                                    </div>
                                    <div className="subscribe-bottom-items">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-bottom-signal" viewBox="0 0 40 40" id="tick"><path d="M15.48 28.62a1 1 0 0 1-.71-.29l-7.54-7.54a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l6.83 6.83L32.12 9.57a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42L16.18 28.33a1 1 0 0 1-.7.29Z" fill="#28b446" class="color000000 svgShape"></path></svg>
                                    <span className="subscribe-bottom-itemname"> Available on Web, iOS, and Android platforms.</span>
                                    </div>
                                    <div className="subscribe-bottom-items">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-bottom-signal" viewBox="0 0 40 40" id="tick"><path d="M15.48 28.62a1 1 0 0 1-.71-.29l-7.54-7.54a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l6.83 6.83L32.12 9.57a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42L16.18 28.33a1 1 0 0 1-.7.29Z" fill="#28b446" class="color000000 svgShape"></path></svg>
                                    <span className="subscribe-bottom-itemname">Updated data</span>
                                    </div>
                                    <div className="subscribe-bottom-items">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-bottom-signalwrong" viewBox="0 0 50 50" id="Close"><path d="M2.707 48.707 25 26.414l22.293 22.293 1.414-1.414L26.414 25 48.707 2.707l-1.414-1.414L25 23.586 2.707 1.293 1.293 2.707 23.586 25 1.293 47.293z" fill="#28b446" class="color000000 svgShape"></path></svg>
                                    <span className="subscribe-bottom-itemnameno"> Image generation</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className={cardPlanref.current == "Premium subscription" ? "subscribe-firstcard hover" : "subscribe-firstcard"}>
                        <div className="subscribe-innercard-top">
                            <div className="subscribe-inercard-first">
                                <div className="subscribe-inercard-firstline">
                                    <span className="subscribe-inercard-firstline-left">
                                     Premium
                                    </span>
                                </div>
                                <span className="subscribe-inercard-secondline">
                                Enjoy all the standard benefits plus advanced image generation features.
                                </span>
                                <div className="subscribe-inercard-thirdline">
                                    <div className="d-flex">
                                        <span className="subscribe-offer-bold">$20</span>
                                        <div className="subscribe-offer-main">
                                            <span className="subscribe-week">/Monthly</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="subscribe-inercard-second" onClick={() => handleSubmit(3)}>
                                <span className="subscribe-btn"> Subscribe</span>
                            </div>
                            </div>
                            <hr className="subscribe-horizon" /> 
                            <div className="subscribe-innercard-bottom">
                                <span className="subscribe-bottom-head" >What’s Included</span>
                                <div className="subscribe-innercard-bottomsecond">
                                    <div className="subscribe-bottom-items">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-bottom-signal" viewBox="0 0 40 40" id="tick"><path d="M15.48 28.62a1 1 0 0 1-.71-.29l-7.54-7.54a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l6.83 6.83L32.12 9.57a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42L16.18 28.33a1 1 0 0 1-.7.29Z" fill="#28b446" class="color000000 svgShape"></path></svg>
                                    <span className="subscribe-bottom-itemname">  Unlimited messaging, interactions and past chats</span>
                                    </div>
                                    <div className="subscribe-bottom-items">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-bottom-signal" viewBox="0 0 40 40" id="tick"><path d="M15.48 28.62a1 1 0 0 1-.71-.29l-7.54-7.54a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l6.83 6.83L32.12 9.57a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42L16.18 28.33a1 1 0 0 1-.7.29Z" fill="#28b446" class="color000000 svgShape"></path></svg>
                                    <span className="subscribe-bottom-itemname">  Available on Web, iOS, and Android platforms.</span>
                                    </div>
                                    <div className="subscribe-bottom-items">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-bottom-signal" viewBox="0 0 40 40" id="tick"><path d="M15.48 28.62a1 1 0 0 1-.71-.29l-7.54-7.54a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l6.83 6.83L32.12 9.57a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42L16.18 28.33a1 1 0 0 1-.7.29Z" fill="#28b446" class="color000000 svgShape"></path></svg>
                                    <span className="subscribe-bottom-itemname">  Updated data</span>
                                    </div>
                                    <div className="subscribe-bottom-items">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="subscribe-bottom-signal" viewBox="0 0 40 40" id="tick"><path d="M15.48 28.62a1 1 0 0 1-.71-.29l-7.54-7.54a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l6.83 6.83L32.12 9.57a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42L16.18 28.33a1 1 0 0 1-.7.29Z" fill="#28b446" class="color000000 svgShape"></path></svg>
                                    <span className="subscribe-bottom-itemname">   Image generation </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                          </div>

                      </div>
                 
                    
                  </div>
                </div>
              </Grid>
            </Grid>
        </div>
      

  );
}

export default Chat;
