import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import Header from "./Header";
import { env } from "../envconfig";
import axios from 'axios';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { toast } from "react-toastify";

function Register() {

  const BACKEND_URL = env.BACKEND_URL;
  const navigate = useNavigate();

    const initialFormValue = {
      email: "",
      password: "",
      confirmPassword: "",
    }

    const [validationnErr, setvalidationnErr] = useState("");
    const [formValue, setFormValue] = useState(initialFormValue);
    const [emailValidate, setemailValidate,emailValidateref] = useState(false);
    const [passwordValidate, setpasswordValidate,passwordValidateref] = useState(false);
    const [confirmPasswordValidate, setconfirmPasswordValidate,confirmPasswordValidateref] = useState(false);

    const [passHide, setPasshide] = useState(false);
    const [confPassHide, setconfPasshide] = useState(false);
    const [inputType, setInputType] = useState("password");
    const [confInputType, setconfInputType] = useState("password");
    const [respose,setResponse] = useState("");
    const [isLoading,setIsLoading] = useState(false);


    const {
      email,
      password,
      confirmPassword,
    } = formValue;

    const handleChange = async (e) => {
      e.preventDefault();
      const {name, value} = e.target;
      let formData = {...formValue, ...{[name]: value}};
      setFormValue(formData);
      validate(formData);
    };

    const validate = async (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = "Email is a required field";
        setemailValidate(true);
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Invalid email address";
        setemailValidate(true);
      } else {
        setemailValidate(false);
      }

      if (!values.password) {
        errors.password = "Password is a required field";
        setpasswordValidate(true);
      } else if (!values.password.match(/[a-z]/g)) {
        setpasswordValidate(true);
        errors.password = "Please enter at least one lower character";
      } else if (!values.password.match(/[A-Z]/g)) {
        setpasswordValidate(true);
        errors.password = "Please enter at least one upper character";
      } else if (!values.password.match(/[0-9]/g)) {
        setpasswordValidate(true);
        errors.password = "Please enter at least one digit";
      } else if (!values.password.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
        setpasswordValidate(true);
        errors.password = "Please enter at least one special character";
      } else if (values.password.length < 6) {
        setpasswordValidate(true);
        errors.password = "Atleast enter 6 letters";
      } else if (values.password.length > 15) {
        setpasswordValidate(true);
        errors.password = "Only enter 15 letters";
      } else {
        setpasswordValidate(false);
      }

      if (!values.confirmPassword) {
        errors.confirmPassword = "Confirm password is a required field";
        setconfirmPasswordValidate(true);
      } else if (
        values.password &&
        values.confirmPassword &&
        values.password !== values.confirmPassword
      ) {
        errors.confirmPassword = "Password and confirm password does not match";
        setconfirmPasswordValidate(true);
      } else {
        setconfirmPasswordValidate(false);
      }
  
      setvalidationnErr(errors);
      return errors;
    }

    const passwordHide = (data) => {
      if (data == "hide") {
        setPasshide(true);
        setInputType("text");
      } else {
        setPasshide(false);
        setInputType("password");
      }
    };
    const confirmPasswordHide = (data) => {
      if (data == "hide") {
        setconfPasshide(true);
        setconfInputType("text");
      } else {
        setconfPasshide(false);
        setconfInputType("password");
      }
    };

    const submit = async () => {
      try {
        validate(formValue);
        if(
          emailValidateref.current === false &&
          passwordValidateref.current === false &&
          confirmPasswordValidateref.current === false
        ){
          setIsLoading(true);
          const response = await axios.post(`${BACKEND_URL}/user/register`,formValue);
          setIsLoading(false);
          if(response.data.status == true){
            toast.success(response.data.Message);
            navigate("/login");
          }else{
            toast.error(response.data.Message)
          }
          setResponse(response.data);
        }
      } catch (error) {
        // console.log("register not entered");
      }
    }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6">
            <Header/>
            <div className="row justify-content-center mt-4 mb-3">
              <div className="col-lg-8">
                <div className="login-card">
                  <div className="login-innercard">
                    <span class="Lets-get-started">Let’s get started!</span>
                    {/* <div className="google-login">
                      <img src={require("../images/Google.png")} />
                      <span class="Continue-with-Google">
                        Continue with Google
                      </span>
                    </div>
                    <div class="or-line-container">
                      <div class="or-line"></div>
                      <div class="or-text">or</div>
                      <div class="or-line"></div>
                    </div> */}
                    <div className="login-email">
                      <span className="login-label"> E-mail </span>
                      <input
                        type="text"
                        placeholder="Enter your email to continue"
                        className="login-emailinput"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      ></input>
                       {emailValidate == true ? (
                            <p className="text-danger error-css">
                              {" "}
                              {validationnErr.email}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                    </div>
                    <div className="login-password">
                      <span className="login-label">Password</span>
                      <div className="flex_input_posion">
                        <input
                           type={inputType}
                          placeholder="Enter your password"
                          name="password"
                          className="login-passwordinputfield"
                          value={password}
                          onChange={handleChange}
                        ></input>
                         {passHide == false ? (
                            <i
                              className="bi bi-eye-slash-fill eye-color"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye-fill eye-color"
                              onClick={() => passwordHide("show")}
                            ></i>
                          )}

                      </div>
                      {passwordValidate == true ? (
                            <p className="text-danger error-css">
                              {" "}
                              {validationnErr.password}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                    </div>
                    <div className="login-password">
                      <span className="login-label">Confirm Password</span>
                      <div className="flex_input_posion">
                        <input
                         type={confInputType}
                          placeholder="Re-enter your password"
                          name="confirmPassword"
                          className="login-passwordinputfield"
                          value={confirmPassword}
                            onChange={handleChange}
                        ></input>
                        {confPassHide == false ? (
                            <i
                              className="bi bi-eye-slash-fill eye-color"
                              onClick={() => confirmPasswordHide("hide")}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye-fill eye-color"
                              onClick={() => confirmPasswordHide("show")}
                            ></i>
                          )}
                      </div>
                      {confirmPasswordValidate == true ? (
                            <p className="text-danger error-css">
                              {" "}
                              {validationnErr.confirmPassword}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                    </div>
                    <div className="login-btndiv mt-3">
                      {isLoading == true ? (
                        <button className="login-button"> Loading  </button>
                      ):(
                        <button className="login-button" onClick={submit}>Sign Up</button>
                      )}
                     
                    </div>
                  </div>
                  <div className="login-innercard2">
                    {/* <p className="login-terms">
                      By clicking "Sign up" or "Continue with Google" , you
                      agree to the NewAI's
                      <Link to="">Terms & Condition </Link> and{" "}
                      <Link to="">Privacy Policy</Link>
                    </p> */}
                     <p className="login-terms">
                      By clicking "Sign up", you
                      agree to the NewAI's
                      <Link to="">Terms & Condition </Link> and{" "}
                      <Link to="">Privacy Policy</Link>
                    </p>
                    <p className="login-terms2">
                      Already have an account? <Link to="/login"> Log in </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 slider-main">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="sliderone">
                  <div className="relative">
                    <div className="slider-rectangle">
                      <div className="slider-items">
                        <img
                          src={require("../images/Ellipse 1.png")}
                          className="slider-image"
                        ></img>
                        <p className="slider-content">
                          {" "}
                          “A surreal landscape with a mirror-like lake, vibrant
                          lotus flowers, fantastical trees, and mist-covered
                          mountains under a swirling, colorful sky.”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slidertwo">
                  <div className="relative">
                    <div className="slider-rectangle">
                      <div className="slider-items">
                        <img
                          src={require("../images/Ellipse 2.png")}
                          className="slider-image"
                        ></img>
                        <p className="slider-content">
                          “Create an image of a beautiful orange cat which is
                          sitting on a hill with a golden sun kissing face and
                          with a beautiful, stunning background.”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="sliderthree">
                  <div className="relative">
                    <div className="slider-rectangle">
                      <div className="slider-items">
                        <img
                          src={require("../images/Ellipse 3.png")}
                          className="slider-image"
                        ></img>
                        <p className="slider-content">
                          “Generate an image of a african kid with a authentic
                          dress standing in a paddy field on a evening time.
                          make the light golden”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="sliderfour">
                  <div className="relative">
                    <div className="slider-rectangle">
                      <div className="slider-items">
                        <img
                          src={require("../images/Ellipse 4.png")}
                          className="slider-image"
                        ></img>
                        <p className="slider-content">
                          “A surreal landscape with a mirror-like lake, vibrant
                          lotus flowers, fantastical trees, and mist-covered
                          mountains under a swirling, colorful sky.”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
