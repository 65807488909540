import React, {useState} from "react";
import {Link, useNavigate, NavLink} from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import Drawer from "./Drawer";
// import {Grid, Paper, Container} from "@mui/material";
// import {Button} from "semantic-ui-react";
// import {Checkbox} from "semantic-ui-react";
// import {Dropdown} from "semantic-ui-react";

function SidebarNew() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>

  {isMatch ? (
            <>
              <Drawer />
            </>
          ) : (
            <>
        <aside className="asidemeni">
        <div className="mennu_sidemain">
          <Link to="/">
          <div className='landing-header1chat'>
            
            <img
                    src={require("../images/Ellipse 2.png")}
                    className="header-image"
            ></img>
            <span className='logo-namelandingchat'>New AI</span>
          
           </div>
           </Link>
        
           <div className="mennu_side">
           <div className="chat-optionside">
           <div className="menu_items menu_items_fex">
            <NavLink to="/chathome" className="navlink_new">
            <i class="bi bi-house-door side-icon"></i>
              <span className="side-menus">Home </span>
            </NavLink>
          </div>
          <div className="menu_items menu_items_fex">
            <NavLink to="/chattingbot" className='navlink_new'>
            <svg xmlns="http://www.w3.org/2000/svg" className="side-svg" viewBox="0 0 256 256" id="ChatsCircle"><rect width="256" height="256" fill="none"></rect><path fill="none" stroke="#6c6c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" d="M34.0714,140.74858a71.96979,71.96979,0,1,1,25.18031,25.1802l.00017-.00075-24.86742,7.105a6,6,0,0,1-7.41747-7.41747l7.105-24.86742Z" class="colorStroke000000 svgStroke"></path><path fill="none" stroke="#6c6c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" d="M92.059,175.89247a72.04349,72.04349,0,0,0,104.68926,38.03631l-.00017-.00075,24.86742,7.105a6,6,0,0,0,7.41747-7.41747l-7.105-24.86742.00057.00046A72.01951,72.01951,0,0,0,163.93781,80.10585" class="colorStroke000000 svgStroke"></path></svg>
            <span>Chats</span> 
            </NavLink>
          </div>

          <div className="menu_items menu_items_fex">
            <NavLink to="/imagecreation" className="navlink_new">
            <svg xmlns="http://www.w3.org/2000/svg" className="side-svg" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="Photos"><path d="M26,7h-1V6c0-1.6543-1.3457-3-3-3H6C4.3457,3,3,4.3457,3,6v16c0,1.6543,1.3457,3,3,3h1v1c0,1.6543,1.3457,3,3,3h16c1.6543,0,3-1.3457,3-3V10C29,8.3457,27.6543,7,26,7z M6,5h16c0.5518,0,1,0.4487,1,1v5.6237l-6.1006,6.973l-6.249-5.356c-0.3965-0.3403-0.9883-0.3179-1.3574,0.0522L5,17.5859V6C5,5.4487,5.4482,5,6,5z M27,26c0,0.5513-0.4482,1-1,1H10c-0.5518,0-1-0.4487-1-1v-1h13c1.6543,0,3-1.3457,3-3V9h1c0.5518,0,1,0.4487,1,1V26z" fill="#6c6c89" class="color000000 svgShape"></path><path d="M16,13c1.6543,0,3-1.3457,3-3s-1.3457-3-3-3s-3,1.3457-3,3S14.3457,13,16,13z" fill="#6c6c89" class="color000000 svgShape"></path></svg>
            <span>Creations</span>
            </NavLink>
          </div>
         
           </div>
           <div className="chat-optionside">
           {/* <div className="menu_items menu_items_fex">
            <NavLink to="/" className="navlink_new">
            <svg xmlns="http://www.w3.org/2000/svg" className="side-svg2" viewBox="0 0 16 16" id="Exclamation"><path fill="#6c6c89" d="M8 1c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7zm0-1C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z" class="color444444 svgShape"></path><path fill="#6c6c89" d="M7 3h2v7H7V3zM7 11h2v2H7v-2z" class="color444444 svgShape"></path></svg>
            <span>Support</span> 
            </NavLink>
          </div> */}
          <div className="menu_items menu_items_fex">
            <NavLink to="/subscribe" className="navlink_new">
            <svg xmlns="http://www.w3.org/2000/svg" className="side-svg2" viewBox="0 0 24 24" id="star"><path d="m20.4 14.1-3.2-1.3-1.3-3.2c-.1-.4-.5-.6-.9-.6s-.8.2-.9.6l-1.3 3.2-3.2 1.3c-.4.1-.6.5-.6.9s.2.8.6.9l3.2 1.3 1.3 3.2c.2.4.5.6.9.6s.8-.2.9-.6l1.3-3.2 3.2-1.3c.4-.2.6-.5.6-.9s-.2-.8-.6-.9zm-4.3 1.4c-.2.1-.4.3-.5.5l-.6 1.4-.5-1.3c-.1-.2-.3-.4-.5-.5l-1.4-.6 1.3-.5c.2-.1.4-.3.5-.5l.5-1.3.5 1.3c.1.2.3.4.5.5l1.3.5-1.1.5zM10 10.7c0-.4-.2-.8-.6-.9l-1.5-.7-.7-1.5c-.1-.4-.5-.6-.9-.6s-.8.2-.9.6l-.7 1.5-1.5.7c-.4.2-.6.5-.6.9s.2.8.6.9l1.5.7.7 1.5c.2.4.5.6.9.6s.8-.2.9-.6l.7-1.5 1.5-.7c.4-.1.6-.5.6-.9zm-3.7.2c-.1-.1-.1-.2-.2-.2.1-.1.2-.1.2-.2.1.1.1.2.2.2l-.2.2zm7.9-4.5.9.4.4.9c.2.4.5.6.9.6s.8-.2.9-.6l.4-.9.9-.4c.4-.2.6-.5.6-.9s-.2-.8-.6-.9l-.9-.4-.4-.9c-.2-.4-.5-.6-.9-.6s-.8.2-.9.6l-.4.9-.9.4c-.4.2-.6.5-.6.9s.3.8.6.9z" fill="#6c6c89" class="color000000 svgShape"></path></svg>
            <span>Subscribe</span> 
            </NavLink>
          </div>
          {/* <div className="menu_items menu_items_fex">
            <NavLink to="/" className="navlink_new">
            <svg xmlns="http://www.w3.org/2000/svg" className="side-svg2" viewBox="0 0 32 32" id="setting"><path d="M27.526 12.682a11.906 11.906 0 0 0-1.028-2.492l1.988-4.182a16.159 16.159 0 0 0-2.494-2.494L21.81 5.502a11.97 11.97 0 0 0-2.492-1.028L17.762.102C17.184.038 16.596 0 16 0s-1.184.038-1.762.102l-1.556 4.372c-.874.252-1.71.596-2.49 1.028L6.008 3.514a16.159 16.159 0 0 0-2.494 2.494l1.988 4.182a11.97 11.97 0 0 0-1.028 2.492L.102 14.238C.038 14.816 0 15.404 0 16s.038 1.184.102 1.762l4.374 1.556c.252.876.594 1.71 1.028 2.492l-1.988 4.182c.738.92 1.574 1.758 2.494 2.494l4.182-1.988c.78.432 1.616.776 2.492 1.028L14.24 31.9c.576.062 1.164.1 1.76.1s1.184-.038 1.762-.102l1.556-4.374c.876-.252 1.71-.594 2.492-1.028l4.182 1.988a16.071 16.071 0 0 0 2.494-2.494l-1.988-4.182a11.97 11.97 0 0 0 1.028-2.492L31.9 17.76c.062-.576.1-1.164.1-1.76s-.038-1.184-.102-1.762l-4.372-1.556zM16 24a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-4-8a4 4 1080 1 0 8 0 4 4 1080 1 0-8 0z" fill="#6c6c89" class="color000000 svgShape"></path></svg>
            <span>Setting</span> 
            </NavLink>
          </div> */}
           </div>
           </div>
         
        

        </div>
      </aside>
            </>
          )}



    </>
  );
}

export default SidebarNew;
