import React , { useEffect, useState }from "react";
import axios from 'axios';
import useStateRef from "react-usestateref";
import { Grid, Paper, Container } from "@mui/material";
import Sidebar from "./Sidebar";
import { Link,useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';
import { env } from "../envconfig";


function ImageCreation() {

  const location = useLocation();
  const promptHere = location.state ? location.state.prompt : "";

  const BACKEND_URL = env.BACKEND_URL;

  useEffect(() => {

    if (promptHere !== ""){
      setreplaceReq(promptHere);
      setrespdata(promptHere);
      ChatPrompt();
    }
   
  }, [])
  

  const [activeButton, setActiveButton] = useState(1);
  const [prompt, setPrompt] = useState('');
  const [command, setCommand] = useState('');
  const [response, setResponse] = useState('');

  const [replaceReq, setreplaceReq, replaceReqref] = useStateRef("");
  const [respdata, setrespdata, respdataref] = useStateRef("");

  const [totalChat, setTotalChat] = useState([]);
  const [isLoading,setIsLoading] = useState(false);

  const getInputValue = (value) => {
    setreplaceReq(value);
    setrespdata(value.trim());
  };

  const handleInputChange = (event) => {
    setPrompt(event.target.value);
  };

    const handleSubmit = async () => {
    setIsLoading(true);
    totalChat.push({
      role: "user",
      content: respdataref.current,
    });
    setreplaceReq("");
   
    const data = {
      prompt: respdataref.current + " in a realistic style", // Appending realism to each prompt
      n: 1, // Number of images to generate
      size: "256x256"
    };

    const headers = {
      Authorization: `Bearer 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 `
    };

    try {
      const response = await axios.post('https://api.openai.com/v1/images/generations', data, { headers });
      const generatedImageURL = response.data.data[0].url; // Accessing the URL of the generated image
      var obj = {
        role: "assist",
        content: generatedImageURL ,
      };
      setTotalChat([...totalChat, obj]);
      setResponse(generatedImageURL);
      setreplaceReq("");
      setIsLoading(false);
      // setPrompt("");
    } catch (error) {
      setreplaceReq("");
      console.error('Error generating image:', error);
    }
};

const handleChange = async (e) => {
  setPrompt(e.target.value);
  setCommand(e.target.value);
}

const ChatPrompt = () => {
  try {
    if(replaceReqref.current == "") {
      toast.error("Please enter a prompt !");
      return;
    }else {
      setActiveButton(2);
      handleSubmit();
    }
  } catch (error) {
    // console.log("")
  }
}

const handleKeyDown = (event) => {
  if (event.keyCode === 13) {
    if (replaceReqref.current == "") {
      toast.error("Please enter a prompt !");
      return;
    } else {
      setActiveButton(2);
      handleSubmit();
    }
  }
};

const [dropstatus, setdropstatus] = useState(false);


const dropdowns = async () => {
  if(dropstatus == true){
    setdropstatus(false);
  }else{
    setdropstatus(true);
  }
};

const imageUrl = require("../images/Chathome/firstinner.png");
const imageUrlsecond = require("../images/Chathome/secondinner.png");
const imageUrlthird = require("../images/Chathome/thirdinner.png");
const imageUrlfourth = require("../images/Chathome/fourthinner.png");
const imageUrlfifth = require("../images/Chathome/fifthinner.png");
const imageUrlsixth = require("../images/Chathome/sixthinner.png");
const imageUrlseventh = require("../images/Chathome/seventhinner.png");
const imageUrleighth = require("../images/Chathome/eighthinner.png");
const imageUrlnineth = require("../images/Chathome/ninethinner.png");

const handleDownloadfirst = () => {
  const link = document.createElement('a');
  link.href = imageUrl;
  link.download = 'NewAI.jpg';
  link.click();
};
const handleDownloadsecond = () => {
  const link = document.createElement('a');
  link.href = imageUrlsecond;
  link.download = 'NewAIsecond.jpg';
  link.click();
};
const handleDownloadthird = () => {
  const link = document.createElement('a');
  link.href = imageUrlthird;
  link.download = 'NewAIthird.jpg';
  link.click();
};
const handleDownloadfourth = () => {
  const link = document.createElement('a');
  link.href = imageUrlfourth;
  link.download = 'NewAIfourth.jpg';
  link.click();
};
const handleDownloadfifth = () => {
  const link = document.createElement('a');
  link.href = imageUrlfifth;
  link.download = 'NewAIfifth.jpg';
  link.click();
}
const handleDownloadsixth = () => {
  const link = document.createElement('a');
  link.href = imageUrlsixth;
  link.download = 'NewAIsixth.jpg';
  link.click();
}
const handleDownloadseventh = () => {
  const link = document.createElement('a');
  link.href = imageUrlseventh;
  link.download = 'NewAIseventh.jpg';
  link.click();
}
const handleDownloadeighth = () => {
  const link = document.createElement('a');
  link.href = imageUrleighth;
  link.download = 'NewAIeighth.jpg';
  link.click();
}
const handleDownloadnineth = () => {
  const link = document.createElement('a');
  link.href = imageUrlnineth;
  link.download = 'NewAInineth.jpg';
  link.click();
}

  const handleDownloadImage= async  (imgDetails) => {
    // console.log(imgDetails, {imgDetails})

    setIsLoading(true);
    const originalImage = imgDetails
    const response = await axios.post(`${BACKEND_URL}/user/downloadImage`, {
      imageUrl: originalImage
    }, {responseType: 'blob'});

    // console.log(response, "-------")

    setIsLoading(false);
    let blob = await response.data
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `FileName.jpeg`,
    );

    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);

    // console.log(response,"=-=-response image download");
  }

  return (
        <div>
          {/* <div className="container chat-side"> */}
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={12} sm={12} md={8} lg={2} xl={2}>
                  <Sidebar />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                <div className="container">
                  <div className="text-center">
                    <div className="chat-homehead-chatting">

                      <div className="chat-homehead-two mt-3">
                        <div className='chatting-homehead-firstbtn'>
                          <div className="menu_items menu_items_fex_chat">
                            <div className="navlink_new" onClick={dropdowns}>
                            <span className="chatting-title">Creations</span> 
                             <svg xmlns="http://www.w3.org/2000/svg" className="side-svg-chatting" viewBox="0 0 24 24" id="chevron-down"><path d="M12,15a1,1,0,0,1-.71-.29l-4-4A1,1,0,0,1,8.71,9.29L12,12.59l3.29-3.29a1,1,0,0,1,1.41,1.41l-4,4A1,1,0,0,1,12,15Z"></path></svg>
                             <div className={ dropstatus == true ? `dropdown-content2 d-block ` : `dropdown-content2` }>
                              <a href="/chattingbot" className="mb-3">
                              <span className="ml-1">Chats</span>
                              </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {activeButton === 1 ? (
                           <div>
                           <div className="row mx-3 mt-5">
                        <div className="col-lg-4 mt-2">
                          <div className="chat-home-firstcard">
                            <div className="chat-home-first-innercard">
                              <div className="chat-home-first-innerphoto">
                                <img src={require('../images/Chathome/firstinner.png')} className="chat-homeimg" />
                              </div>
                              <div className="chat-home-first-innercontent">
                                <span className="chat-home-first-innerspan">Create a stunning image of a cat</span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="chat-homesvg"  onClick={handleDownloadfirst} viewBox="0 0 24 24" id="Download"><g fill="#8a8aa3" class="color000000 svgShape"><g fill="#8a8aa3" class="color000000 svgShape"><rect width="16" height="2" x="4" y="18" rx="1" ry="1" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="3" y="17" rx="1" ry="1" transform="rotate(-90 5 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="17" y="17" rx="1" ry="1" transform="rotate(-90 19 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><path d="M12 15a1 1 0 0 1-.58-.18l-4-2.82a1 1 0 0 1-.24-1.39 1 1 0 0 1 1.4-.24L12 12.76l3.4-2.56a1 1 0 0 1 1.2 1.6l-4 3a1 1 0 0 1-.6.2z" fill="#8a8aa3" class="color000000 svgShape"></path><path d="M12 13a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0v8a1 1 0 0 1-1 1z" fill="#8a8aa3" class="color000000 svgShape"></path></g></g></svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                          <div className="chat-home-firstcard">
                            <div className="chat-home-first-innercard">
                              <div className="chat-home-first-innerphoto">
                                <img src={require('../images/Chathome/secondinner.png')} className="chat-homeimg" />
                              </div>
                              <div className="chat-home-first-innercontent">
                                <span className="chat-home-first-innerspan">  An old tree in a beautiful graden</span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="chat-homesvg" onClick={handleDownloadsecond} viewBox="0 0 24 24" id="Download"><g fill="#8a8aa3" class="color000000 svgShape"><g fill="#8a8aa3" class="color000000 svgShape"><rect width="16" height="2" x="4" y="18" rx="1" ry="1" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="3" y="17" rx="1" ry="1" transform="rotate(-90 5 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="17" y="17" rx="1" ry="1" transform="rotate(-90 19 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><path d="M12 15a1 1 0 0 1-.58-.18l-4-2.82a1 1 0 0 1-.24-1.39 1 1 0 0 1 1.4-.24L12 12.76l3.4-2.56a1 1 0 0 1 1.2 1.6l-4 3a1 1 0 0 1-.6.2z" fill="#8a8aa3" class="color000000 svgShape"></path><path d="M12 13a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0v8a1 1 0 0 1-1 1z" fill="#8a8aa3" class="color000000 svgShape"></path></g></g></svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                          <div className="chat-home-firstcard">
                            <div className="chat-home-first-innercard">
                              <div className="chat-home-first-innerphoto">
                                <img src={require('../images/Chathome/thirdinner.png')} className="chat-homeimg" />
                              </div>
                              <div className="chat-home-first-innercontent">
                                <span className="chat-home-first-innerspan">  A glowing whale in a deep ocean</span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="chat-homesvg" onClick={handleDownloadthird} viewBox="0 0 24 24" id="Download"><g fill="#8a8aa3" class="color000000 svgShape"><g fill="#8a8aa3" class="color000000 svgShape"><rect width="16" height="2" x="4" y="18" rx="1" ry="1" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="3" y="17" rx="1" ry="1" transform="rotate(-90 5 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="17" y="17" rx="1" ry="1" transform="rotate(-90 19 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><path d="M12 15a1 1 0 0 1-.58-.18l-4-2.82a1 1 0 0 1-.24-1.39 1 1 0 0 1 1.4-.24L12 12.76l3.4-2.56a1 1 0 0 1 1.2 1.6l-4 3a1 1 0 0 1-.6.2z" fill="#8a8aa3" class="color000000 svgShape"></path><path d="M12 13a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0v8a1 1 0 0 1-1 1z" fill="#8a8aa3" class="color000000 svgShape"></path></g></g></svg>
                              </div>
                            </div>
                          </div>
                        </div>
                            </div>
  
                            <div className="row mx-3 mt-3">
                              <div className="col-lg-4 mt-2">
                                <div className="chat-home-firstcard">
                                  <div className="chat-home-first-innercard">
                                    <div className="chat-home-first-innerphoto">
                                      <img src={require('../images/Chathome/fourthinner.png')} className="chat-homeimg" />
                                    </div>
                                    <div className="chat-home-first-innercontent">
                                      <span className="chat-home-first-innerspan">Create a beautiful dog With a blue eyes</span>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="chat-homesvg" onClick={handleDownloadfourth} viewBox="0 0 24 24" id="Download"><g fill="#8a8aa3" class="color000000 svgShape"><g fill="#8a8aa3" class="color000000 svgShape"><rect width="16" height="2" x="4" y="18" rx="1" ry="1" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="3" y="17" rx="1" ry="1" transform="rotate(-90 5 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="17" y="17" rx="1" ry="1" transform="rotate(-90 19 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><path d="M12 15a1 1 0 0 1-.58-.18l-4-2.82a1 1 0 0 1-.24-1.39 1 1 0 0 1 1.4-.24L12 12.76l3.4-2.56a1 1 0 0 1 1.2 1.6l-4 3a1 1 0 0 1-.6.2z" fill="#8a8aa3" class="color000000 svgShape"></path><path d="M12 13a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0v8a1 1 0 0 1-1 1z" fill="#8a8aa3" class="color000000 svgShape"></path></g></g></svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 mt-2">
                                <div className="chat-home-firstcard">
                                  <div className="chat-home-first-innercard">
                                    <div className="chat-home-first-innerphoto">
                                      <img src={require('../images/Chathome/fifthinner.png')} className="chat-homeimg" />
                                    </div>
                                    <div className="chat-home-first-innercontent">
                                      <span className="chat-home-first-innerspan"> Create a kid running with a kite</span>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="chat-homesvg" onClick={handleDownloadfifth} viewBox="0 0 24 24" id="Download"><g fill="#8a8aa3" class="color000000 svgShape"><g fill="#8a8aa3" class="color000000 svgShape"><rect width="16" height="2" x="4" y="18" rx="1" ry="1" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="3" y="17" rx="1" ry="1" transform="rotate(-90 5 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="17" y="17" rx="1" ry="1" transform="rotate(-90 19 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><path d="M12 15a1 1 0 0 1-.58-.18l-4-2.82a1 1 0 0 1-.24-1.39 1 1 0 0 1 1.4-.24L12 12.76l3.4-2.56a1 1 0 0 1 1.2 1.6l-4 3a1 1 0 0 1-.6.2z" fill="#8a8aa3" class="color000000 svgShape"></path><path d="M12 13a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0v8a1 1 0 0 1-1 1z" fill="#8a8aa3" class="color000000 svgShape"></path></g></g></svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 mt-2">
                                <div className="chat-home-firstcard">
                                  <div className="chat-home-first-innercard">
                                    <div className="chat-home-first-innerphoto">
                                      <img src={require('../images/Chathome/sixthinner.png')} className="chat-homeimg" />
                                    </div>
                                    <div className="chat-home-first-innercontent">
                                      <span className="chat-home-first-innerspan">Create a robot arm in purple background</span>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="chat-homesvg" onClick={handleDownloadsixth} viewBox="0 0 24 24" id="Download"><g fill="#8a8aa3" class="color000000 svgShape"><g fill="#8a8aa3" class="color000000 svgShape"><rect width="16" height="2" x="4" y="18" rx="1" ry="1" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="3" y="17" rx="1" ry="1" transform="rotate(-90 5 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="17" y="17" rx="1" ry="1" transform="rotate(-90 19 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><path d="M12 15a1 1 0 0 1-.58-.18l-4-2.82a1 1 0 0 1-.24-1.39 1 1 0 0 1 1.4-.24L12 12.76l3.4-2.56a1 1 0 0 1 1.2 1.6l-4 3a1 1 0 0 1-.6.2z" fill="#8a8aa3" class="color000000 svgShape"></path><path d="M12 13a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0v8a1 1 0 0 1-1 1z" fill="#8a8aa3" class="color000000 svgShape"></path></g></g></svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            <div className="row mx-3 mt-3 chat-lastrow">
                              <div className="col-lg-4 mt-2">
                                <div className="chat-home-firstcard">
                                  <div className="chat-home-first-innercard">
                                    <div className="chat-home-first-innerphoto">
                                      <img src={require('../images/Chathome/seventhinner.png')} className="chat-homeimg" />
                                    </div>
                                    <div className="chat-home-first-innercontent">
                                      <span className="chat-home-first-innerspan">Create a deer standing in a lake</span>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="chat-homesvg" onClick={handleDownloadseventh} viewBox="0 0 24 24" id="Download"><g fill="#8a8aa3" class="color000000 svgShape"><g fill="#8a8aa3" class="color000000 svgShape"><rect width="16" height="2" x="4" y="18" rx="1" ry="1" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="3" y="17" rx="1" ry="1" transform="rotate(-90 5 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="17" y="17" rx="1" ry="1" transform="rotate(-90 19 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><path d="M12 15a1 1 0 0 1-.58-.18l-4-2.82a1 1 0 0 1-.24-1.39 1 1 0 0 1 1.4-.24L12 12.76l3.4-2.56a1 1 0 0 1 1.2 1.6l-4 3a1 1 0 0 1-.6.2z" fill="#8a8aa3" class="color000000 svgShape"></path><path d="M12 13a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0v8a1 1 0 0 1-1 1z" fill="#8a8aa3" class="color000000 svgShape"></path></g></g></svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 mt-2">
                                <div className="chat-home-firstcard">
                                  <div className="chat-home-first-innercard">
                                    <div className="chat-home-first-innerphoto">
                                      <img src={require('../images/Chathome/eighthinner.png')} className="chat-homeimg" />
                                    </div>
                                    <div className="chat-home-first-innercontent">
                                      <span className="chat-home-first-innerspan">Create a eagle flying in mountain view</span>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="chat-homesvg" onClick={handleDownloadeighth} viewBox="0 0 24 24" id="Download"><g fill="#8a8aa3" class="color000000 svgShape"><g fill="#8a8aa3" class="color000000 svgShape"><rect width="16" height="2" x="4" y="18" rx="1" ry="1" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="3" y="17" rx="1" ry="1" transform="rotate(-90 5 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="17" y="17" rx="1" ry="1" transform="rotate(-90 19 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><path d="M12 15a1 1 0 0 1-.58-.18l-4-2.82a1 1 0 0 1-.24-1.39 1 1 0 0 1 1.4-.24L12 12.76l3.4-2.56a1 1 0 0 1 1.2 1.6l-4 3a1 1 0 0 1-.6.2z" fill="#8a8aa3" class="color000000 svgShape"></path><path d="M12 13a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0v8a1 1 0 0 1-1 1z" fill="#8a8aa3" class="color000000 svgShape"></path></g></g></svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 mt-2">
                                <div className="chat-home-firstcard">
                                  <div className="chat-home-first-innercard">
                                    <div className="chat-home-first-innerphoto">
                                      <img src={require('../images/Chathome/ninethinner.png')} className="chat-homeimg" />
                                    </div>
                                    <div className="chat-home-first-innercontent">
                                      <span className="chat-home-first-innerspan">Create a person standing in magic desert</span>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="chat-homesvg" onClick={handleDownloadnineth} viewBox="0 0 24 24" id="Download"><g fill="#8a8aa3" class="color000000 svgShape"><g fill="#8a8aa3" class="color000000 svgShape"><rect width="16" height="2" x="4" y="18" rx="1" ry="1" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="3" y="17" rx="1" ry="1" transform="rotate(-90 5 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><rect width="4" height="2" x="17" y="17" rx="1" ry="1" transform="rotate(-90 19 18)" fill="#8a8aa3" class="color000000 svgShape"></rect><path d="M12 15a1 1 0 0 1-.58-.18l-4-2.82a1 1 0 0 1-.24-1.39 1 1 0 0 1 1.4-.24L12 12.76l3.4-2.56a1 1 0 0 1 1.2 1.6l-4 3a1 1 0 0 1-.6.2z" fill="#8a8aa3" class="color000000 svgShape"></path><path d="M12 13a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0v8a1 1 0 0 1-1 1z" fill="#8a8aa3" class="color000000 svgShape"></path></g></g></svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      ):(

                        <div className="mx-5">
                        <div className="container mt-5">
                          {totalChat.length > 0 ? (
                            <div className="chatheight">
                              {totalChat.map((data, index) => {
                                return (
                                  <div key={index} className="chatting-user mx-5">
                                    <div className="chatting-user-head mt-3">
                                      {data.role == "user" ? (
                                        <>
                                          <img
                                            src={require("../images/Ellipse 2.png")}
                                            className="header-image"
                                          ></img>
                                          <span className="logo-namelanding-chatting">
                                            you
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={require("../images/Ellipse 1.png")}
                                            className="header-image"
                                          ></img>
                                          <span className="logo-namelanding-chatting">
                                            New AI
                                          </span>
                                        </>
                                      )}
                                    </div>
                                      <div className="chatting-user-subhead">
                                      <span className="chatting-prompt">
                                        <div className="d-flex align-items-start">
                                          
                                          {data.role == "user" ? (
                                              <p>{data.content.split('\n').map((paragraph, index) => (
                                                <React.Fragment key={index}>
                                                  {paragraph}
                                                  <br />
                                                </React.Fragment>
                                              ))}</p>
                                          ) : (
                                            <>
                                            <div className="creation-image">
                                              <img src={data.content} alt="Ai image" />
                                              {/* <img src={data.content} alt="Ai image" onClick={() => handleDownload(data.content)} /> */}
                                              <div className="image-hover" >
                                                {/* <a>down</a> */}
                                                {isLoading == true ? (
                                                  <i class="fa-solid fa-spinner fa-spin fa-2xl text-white"></i>
                                                ): (
                                                  <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleDownloadImage(data.content)} className="side-svg-download" viewBox="0 0 64 64" id="download"><path d="M30.6,38.44a2,2,0,0,0,2.79,0l7-6.83a2,2,0,1,0-2.79-2.86L34,32.26V17a2,2,0,0,0-4,0V32.26l-3.6-3.51a2,2,0,0,0-2.79,2.86Z" fill="#ffffff" class="color000000 svgShape"></path><path d="M43.24,36.27a2,2,0,0,0-2,2v2A2.74,2.74,0,0,1,38.5,43h-13a2.74,2.74,0,0,1-2.74-2.74v-2a2,2,0,0,0-4,0v2A6.75,6.75,0,0,0,25.5,47h13a6.75,6.75,0,0,0,6.74-6.74v-2A2,2,0,0,0,43.24,36.27Z" fill="#ffffff" class="color000000 svgShape"></path><path d="M32,3A29,29,0,1,0,61,32,29,29,0,0,0,32,3Zm0,54A25,25,0,1,1,57,32,25,25,0,0,1,32,57Z" fill="#ffffff" class="color000000 svgShape"></path></svg> 
                                                )}
                                                 </div> 
                                            </div>
                                             
                                             
                                           </>
                                          )}
                                              
                                         </div>
                                      </span>
                                    </div>
                                
                                  </div>
                                );
                              })}
                              {isLoading== false ? (
                                ""
                              ):(
                              <>
                               <div className="chat-loading-head ml-3">
                                <div className="container chat-loading">
                                  <div className="chatting-user-head ml-3 mt-2">
                                          <img
                                          src={require("../images/Ellipse 1.png")}
                                          className="header-image"
                                        ></img>
                                        <span className="logo-namelanding-chatting">
                                          New AI
                                        </span>
                                    </div>
                                  </div>
                                 
                                <span className="text-start m-5">
                                  <span className="loading-font mx-4 my-3">
                                  <i class="fa-solid fa-circle fa-sm fa-beat-fade"></i>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="side-svg-load" viewBox="0 0 24 24" id="Hourglass"><line x1="4" x2="20" y1="3" y2="3" fill="none" stroke="#6c6c89" stroke-linejoin="round" stroke-width="2" class="colorStroke000000 svgStroke"></line><line x1="4" x2="20" y1="21" y2="21" fill="none" stroke="#6c6c89" stroke-linejoin="round" stroke-width="2" class="colorStroke000000 svgStroke"></line><path fill="none" stroke="#6c6c89" stroke-linejoin="round" stroke-width="2" d="M7,3V7a5,5,0,0,0,5,5h0a5,5,0,0,1,5,5v4" class="colorStroke000000 svgStroke"></path><path fill="none" stroke="#6c6c89" stroke-linejoin="round" stroke-width="2" d="M17,3V7a5,5,0,0,1-5,5h0a5,5,0,0,0-5,5v4" class="colorStroke000000 svgStroke"></path><path fill="none" stroke="#6c6c89" stroke-linejoin="round" stroke-width="2" d="M10 6V7a2 2 0 0 0 2 2h0M14 18V17a2 2 0 0 0-2-2h0" class="colorStroke000000 svgStroke"></path></svg> */}
                                  </span>
                                  </span>
                               </div>
                              </>
                               
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      )}


                    <div className="chat-footer">
                      <div className="row chat-foot-row">
                        <div className="col-lg-12">
                        <div className="chat-home-footer">
                        <div className="chatbox">
                          <div className="chatbox-innerleft">
                          <svg xmlns="http://www.w3.org/2000/svg"  className="chat-homesvg" viewBox="0 0 24 24" id="Attachment"><g fill="#8a8aa3" class="color000000 svgShape"><path d="M9.29 21a6.23 6.23 0 0 1-4.43-1.88 6 6 0 0 1-.22-8.49L12 3.2A4.11 4.11 0 0 1 15 2a4.48 4.48 0 0 1 3.19 1.35 4.36 4.36 0 0 1 .15 6.13l-7.4 7.43a2.54 2.54 0 0 1-1.81.75 2.72 2.72 0 0 1-1.95-.82 2.68 2.68 0 0 1-.08-3.77l6.83-6.86a1 1 0 0 1 1.37 1.41l-6.83 6.86a.68.68 0 0 0 .08.95.78.78 0 0 0 .53.23.56.56 0 0 0 .4-.16l7.39-7.43a2.36 2.36 0 0 0-.15-3.31 2.38 2.38 0 0 0-3.27-.15L6.06 12a4 4 0 0 0 .22 5.67 4.22 4.22 0 0 0 3 1.29 3.67 3.67 0 0 0 2.61-1.06l7.39-7.43a1 1 0 1 1 1.42 1.41l-7.39 7.43A5.65 5.65 0 0 1 9.29 21z" fill="#8a8aa3" class="color000000 svgShape"></path></g></svg>
                          <textarea
                            id="custom-textarea"
                            className="custom-textarea"
                            placeholder="Start your creations here"
                            rows={1}
                            value={replaceReqref.current}
                            onChange={(e) => getInputValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                          ></textarea>
                          <svg xmlns="http://www.w3.org/2000/svg"  onClick={ChatPrompt} className="send" viewBox="0 0 24 24" id="send"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M3.4 20.4l17.45-7.48c.81-.35.81-1.49 0-1.84L3.4 3.6c-.66-.29-1.39.2-1.39.91L2 9.12c0 .5.37.93.87.99L17 12 2.87 13.88c-.5.07-.87.5-.87 1l.01 4.61c0 .71.73 1.2 1.39.91z" fill="#8a8aa3" class="color000000 svgShape"></path></svg>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <span className="chat-footer-sub">
                        NewAi may display inaccurate info, including about people, so double-check its responses.
                        <Link to="" >Your privacy and NewAi Apps</Link>
                        </span>
                      </div>
                        </div>
                      </div>
                    </div>
  
                   

                    
                  </div>
                </div>
              </Grid>
            </Grid>
          {/* </div> */}
        </div>
      


  );
}

export default ImageCreation;
