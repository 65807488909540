import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { removeAuthToken } from './core/lib/localStorage';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Register';
import Chat from './pages/Chat';
import Forget from './pages/Forget';
import Subscribe from './pages/Subscribe';
import ChattingBot from './pages/ChattingPage';
import ImageCreation from './pages/ImageCreation';
import './App.css';

function App() {

  function RequireAuth({children}) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  return (
    <>
     <BrowserRouter>
     <ToastContainer />
     <Routes>
      <Route path="/" element={<Home/>} />
      <Route path='/login'element={<Login/>} />
      <Route path='/signup' element={<Signup/>} />
      <Route path='/chathome' element={<Chat/>} />
      <Route path='/forget' element={<Forget/>} />
      <Route path='/subscribe' element={<Subscribe/>} />
      <Route path='/chattingbot' element={<ChattingBot/>} />
      <Route path='/imagecreation' element={
        <RequireAuth>
          <ImageCreation/>
        </RequireAuth>
      } />
     </Routes>
     </BrowserRouter>
    </>
  )
}

export default App;