import React from 'react';
import { Link } from 'react-router-dom';

function Header() {

  return (
    <div className='header-nav'>
      <Link to="/">
        <div className='header'>
            <img
                  src={require("../images/Ellipse 2.png")}
                  className="header-image"
              ></img>
              <span className='logo-name'>New AI</span>
        </div>
      </Link>
    </div>
  )
}

export default Header;