import React, { useState,useEffect } from 'react';
import {
    Drawer,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
  } from "@mui/material";
  import { Link, useNavigate } from "react-router-dom";
  import MenuIcon from "@mui/icons-material/Menu";
  
const pages = ["Home", "Chats", "Creations", "Support","Subscribe","Setting"];

function DrawerComp() {
    const [openDrawer, setOpenDrawer] = useState(false);
    const drawerWidth = 240;
    const zIndex1 = 2000000;

    const [loginStatus,setLoginStatus] = useState(false);

    useEffect(() => {
      if (localStorage.getItem("user_token")){
        setLoginStatus(true);
      }
    }, [])
    

    const navigateToSection = (sectionId) => {
        setOpenDrawer(false);
        // Wait for the drawer to close
        setTimeout(() => {
          const section = document.querySelector(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 300); // adjust the timeout if needed
      };
  return (
    <React.Fragment>
    <Drawer
      anchor="left"
      open={openDrawer}
      className="DRAWERS"
      sx={{
        display: { xs: "block",lg:"block", sm: "block" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          zIndex: zIndex1,
        },
      }}
      onClose={() => setOpenDrawer(false)}
    >
      {loginStatus == true ? (
      <List>
      <>
        <ListItemButton>
          {" "}
          <ListItemIcon>
            <ListItemText>
              <Link className="nav_logo_flash" to="/">
              <div className='landing-header1'>
                  <img
                          src={require("../images/Ellipse 2.png")}
                          className="header-image"
                  ></img>
                  <span className='logo-namelandingside'>New AI</span>
              </div>
              </Link>
            </ListItemText>
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton>
          <ListItemIcon>
            <ListItemText>
              <Link to="/chathome">
                <span
                className='drawer-list'
                >
                  Home
                </span>
              </Link>
             
            </ListItemText>
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton>
          <ListItemIcon>
            <ListItemText>
            <Link to="/chattingbot">
            <span
              className='drawer-list'
              >
                Chats
              </span>
              </Link>
            
            </ListItemText>
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton>
          <ListItemIcon>
            <ListItemText>
            <Link to="/imagecreation">
            <span
              className='drawer-list'
              >
                Creations
              </span>
              </Link>
             
            </ListItemText>
          </ListItemIcon>
        </ListItemButton>
        {/* <ListItemButton>
          <ListItemIcon>
            <ListItemText>
              <span
              className='drawer-list'
                onClick={() => navigateToSection("/")}
              >
                Support
              </span>
            </ListItemText>
          </ListItemIcon>
        </ListItemButton> */}
        <ListItemButton>
          <ListItemIcon>
            <ListItemText>
            <Link to="/subscribe">
            <span
              className='drawer-list'
              >
                Subscribe
              </span>
              </Link>
            
            </ListItemText>
          </ListItemIcon>
        </ListItemButton>
        {/* <ListItemButton>
          <ListItemIcon>
            <ListItemText>
              <span
              className='drawer-list'
                onClick={() => navigateToSection("/")}
              >
                Setting
              </span>
            </ListItemText>
          </ListItemIcon>
        </ListItemButton> */}
      </>
    </List>   
      ) : ("")}

    </Drawer>
    <div className='drawer-menuicon'>
          <Link to="/">
          <div className='landing-header1chat ml-3'>
            <img
                    src={require("../images/Ellipse 2.png")}
                    className="header-image"
            ></img>
            <span className='logo-namelandingside'>New AI</span>
           </div>
          </Link>
        

        <div>
            <IconButton
            sx={{ color: "white", marginLeft: "auto" }}
            onClick={() => setOpenDrawer(!openDrawer)}
            >
            <MenuIcon color="black" />
            </IconButton>
        </div>
    </div>
   
  </React.Fragment>
  )
}

export default DrawerComp;