export const getAuthToken = () => {
    if (localStorage.getItem("user_token")) {
      return localStorage.getItem("user_token");
    }
    return "";
  };
  
  export const removeAuthToken = () => {
    localStorage.removeItem("user_token");
    window.location.href = "/login";
  };