import React ,{ useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const drawerWidth = 240;
// const navItems = ['Home', 'About', 'Contact'];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loginStatus,setLoginStatus] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("user_token")){
      setLoginStatus(true);
    }
  }, [])
  

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center',  backgroundColor: '#0b0b0d' , color: '#fff'}}>
      <Typography variant="h6" sx={{ my: 2 }}>
           <div className='landing-header1'>
            <img
                    src={require("../images/Ellipse 2.png")}
                    className="header-image"
            ></img>
            <span className='logo-namelanding'>New AI</span>
           </div>
      </Typography>
      <Divider />
      {loginStatus == true ? (
            <List>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' , color: '#fff' }}>
                <Link to="/chathome"  className="nav-link" >Home</Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' , color: '#fff' }}>
                <Link to="/chattingbot"  className="nav-link" >Chats</Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' , color: '#fff' }}>
                <Link to="/imagecreation"  className="nav-link" >Creations</Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' , color: '#fff'}}>
                {/* <div> */}
                 <Link to="/subscribe"  className="nav-link" >Subscribe</Link>
                {/* </div>
                <div className='landing-button'>
                  <span className='landing-getstart'>Get Started</span>
                  <i class="bi bi-arrow-right landing-arrow"></i>
              </div> */}
              </ListItemButton>
            </ListItem>
        </List>
      ):("")}
  
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <div className='drawer-head'>
            <div>
            <div className='landing-header1'>
            <img
                    src={require("../images/Ellipse 2.png")}
                    className="header-image"
            ></img>
            <span className='logo-namelanding'>New AI</span>
           </div>
            </div>
            <div>
             <MenuIcon />
            </div>
            </div>
          
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
             <div className='landing-header1'>
            <img
                    src={require("../images/Ellipse 2.png")}
                    className="header-image"
            ></img>
            <span className='logo-namelanding'>New AI</span>
           </div>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {loginStatus == true ? (
              <>
                <Link to="/chathome">
                <Button sx={{ color: '#fff' }}>
                 Home
                </Button>
                </Link>
                
                <Link to="/chattingbot">
                <Button sx={{ color: '#fff' }}>
                 Chats
                </Button>
                </Link>
               
               <Link to="/imagecreation">
               <Button sx={{ color: '#fff' }}>
                 Creations
                </Button>
               </Link>
              
               <Link to="/subscribe">
               <Button sx={{ color: '#fff' }}>
                 Subscribe
                </Button>
               </Link>
              </>
            
            ):("")}
            
            
             <Link to="/login">
              <Button sx={{ color: '#fff' }}>
              <div className='landing-button'>
                <span className='landing-getstart'>Get Started</span>
                <i class="bi bi-arrow-right landing-arrow"></i>
              </div>
              </Button>
              </Link>
             
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;