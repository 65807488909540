import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import Header from "./Header";
import { env } from "../envconfig";
import axios from 'axios';
import { toast } from "react-toastify";
import { setAuthorization } from "../core/service/axios";
import { useGoogleLogin } from "@react-oauth/google";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Login() {

  const BACKEND_URL = env.BACKEND_URL;
  const navigate = useNavigate();

  var initialValues = {
    email: "",
    password: "",
  };

  const [formValue, setFormValue] = useState(initialValues);
  const [vallidation, setVallidation] = useState("");
  const [emailErr, setEmailErr, setEmailErrref] = useState(false);
  const [passwordErr, setPasswordErr, setPasswordErrref] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [respose,setResponse] = useState("");
  const [isLoading,setIsLoading] = useState(false);

  const { email, password } = formValue;

  const handleChange = (e) => {
  
      e.preventDefault();
      const { name, value } = e.target;
      let formData = { ...formValue, ...{ [name]: value } };
      setFormValue(formData);
      validate(formData);
  };

  const validate = () => {
    const error = {};

    if (formValue.email == "") {
      error.email = "Email is a required field";
      setEmailErr(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValue.email)
    ) {
      error.email = "Invalid email address";
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }

    if (formValue.password == "") {
      setPasswordErr(true);
      error.password = "password is required";
    } else if (
      formValue.password.length < 5 ||
      formValue.password.length > 21
    ) {
      setPasswordErr(true);
      error.password = "Password should not below 5 above 21 letters";
    } else if (!formValue.password.match(/[a-z]/g)) {
      setPasswordErr(true);
      error.password = "Please enter at least One lower character";
    } else if (!formValue.password.match(/[A-Z]/g)) {
      setPasswordErr(true);
      error.password = "Please enter at least One upper character";
    } else if (!formValue.password.match(/[0-9]/g)) {
      setPasswordErr(true);
      error.password = "Please enter at One digit character";
    } else {
      setPasswordErr(false);
    }

    setVallidation(error);
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setInputType("text");
    } else {
      setPasshide(false);
      setInputType("password");
    }
  };

  const submit = async () => {
    try {
      validate(formValue);
      if (
        setEmailErrref.current == false &&
        setPasswordErrref.current == false
      ) {
        setIsLoading(true);
        formValue["logintype"] = "Normal";
        const response = await axios.post(`${BACKEND_URL}/user/login`,formValue);
        setIsLoading(false);
        if(response.data.status == true){
          toast.success(response.data.Message);
          await setAuthorization(response.data.token);
          localStorage.setItem("user_token", response.data.token);
          navigate("/chathome");
        }else {
          toast.error(response.data.Message);
        }
        setResponse(response.data);
      }
    } catch (error) {
      // console.log("Login not entered");
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      // console.log(codeResponse,"coderesponse")
      axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then(async (res) => {
        // console.log(res.data);
        formValue["logintype"] = "google";
        formValue["userTokenId"] = res.data.id;
        formValue["userGoogleId"] = res.data.id;
        formValue["userEmail"] = res.data.email;
        
        const response = await axios.post(`${BACKEND_URL}/user/login`,formValue);
        // console.log(response,"login response");
        if(response.data.status){
          toast.success(response.data.Message);
          localStorage.setItem("user_token", response.data.token);
          setAuthorization(response.data.token);
          navigate("/chathome");
        } else{
          toast.error(response.data.Message);
        }
      })
      .catch((err) => console.log(err));
    },
    onError: (error) => console.log("Login Failed:", error)
  })


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6">
            <Header/>
            <div className="row justify-content-center mt-4">
              <div className="col-lg-8">
                <div className="login-card">
                  <div className="login-innercard">
                    <span class="Lets-get-started"> Welcome Back!</span>
                    {/* <div className="google-login" onClick={() => googleLogin()}>
                      <img src={require("../images/Google.png")} />
                      <span class="Continue-with-Google">
                        Log in with Google
                      </span>
                    </div>
                    <div class="or-line-container">
                      <div class="or-line"></div>
                      <div class="or-text">or</div>
                      <div class="or-line"></div>
                    </div> */}
                    <div className="login-email">
                      <span className="login-label"> E-mail </span>
                      <input
                        type="text"
                        placeholder="Enter your email to continue"
                        className="login-emailinput"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      ></input>
                        {emailErr == true ? (
                          <p className="text-danger error-css"> {vallidation.email} </p>
                        ) : (
                          ""
                        )}
                    </div>
                    <div className="login-password">
                      <span className="login-label">Password</span>
                      <div className="flex_input_posion">
                        <input
                          type={inputType}
                          placeholder="Enter your password"
                          className="login-passwordinputfield"
                          name="password"
                          value={password}
                          onChange={handleChange}
                        ></input>
                        {passHide == false ? (
                            <i
                              className="bi bi-eye-slash-fill eye-color"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye-fill eye-color"
                              onClick={() => passwordHide("show")}
                            ></i>
                          )}
                      </div>
                      {passwordErr == true ? (
                          <p className="text-danger error-css">
                            {" "}
                            {vallidation.password}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                    </div>
                    <div className="login-forget">
                      <div className="login-forget2">
                        <Link to="/forget">Forget password ?</Link>
                      </div>
                    </div>
                    <div className="login-btndiv">
                      {isLoading == true ? (
                         <button className="login-button"> Loading </button>
                      ):(
                        <button className="login-button" onClick={submit}>Log in</button>
                      )}
                     
                    </div>
                    <div>
                    <p className="login-terms2">
                      Doesn’t have an account? <Link to="/signup"> Sign Up </Link>
                    </p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 slider-main">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="sliderone">
                  <div className="relative">
                    <div className="slider-rectangle">
                      <div className="slider-items">
                        <img src={require('../images/Ellipse 1.png')} className="slider-image"></img>
                        <p className="slider-content">
                          {" "}
                          “A surreal landscape with a mirror-like lake, vibrant
                          lotus flowers, fantastical trees, and mist-covered
                          mountains under a swirling, colorful sky.”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slidertwo">
                  <div className="relative">
                    <div className="slider-rectangle">
                      <div className="slider-items">
                        <img src={require('../images/Ellipse 2.png')} className="slider-image"></img>
                        <p className="slider-content">
                          “Create an image of a beautiful orange cat which is
                          sitting on a hill with a golden sun kissing face and
                          with a beautiful, stunning background.”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="sliderthree">
                  <div className="relative">
                    <div className="slider-rectangle">
                      <div className="slider-items">
                        <img src={require('../images/Ellipse 3.png')} className="slider-image"></img>
                        <p className="slider-content">
                          “Generate an image of a african kid with a authentic
                          dress standing in a paddy field on a evening time.
                          make the light golden”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="sliderfour">
                  <div className="relative">
                    <div className="slider-rectangle">
                      <div className="slider-items">
                        <img src={require('../images/Ellipse 4.png')} className="slider-image"></img>
                        <p className="slider-content">
                          “A surreal landscape with a mirror-like lake, vibrant
                          lotus flowers, fantastical trees, and mist-covered
                          mountains under a swirling, colorful sky.”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
