import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import LandingHeader from './LandingHeader';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';


function Home() {
    const videoRef = useRef(null);

    useEffect(() => {
        // Play the video when the component mounts
        if (videoRef.current) {
          videoRef.current.play();
        }
      }, []);


  return (
    <div>
        <LandingHeader />
        <section className='generateai-hero-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 hero-left'>
                 <p className='hero-head'> Transform Your Vision Into Reality With AI</p>
                 <p className='hero-subhead'> Unlock the power of AI to elevate your creativity and transform your visions into reality effortlessly.</p>
                 <Link to="/login">
                 <div className='landing-button'>
                    <span className='landing-getstart'>Get Started</span>
                    <i class="bi bi-arrow-right landing-arrow"></i>
                 </div>
                 </Link>
                </div>
                <div className='col-lg-6 hero-right'>
                    <div>
                        <video ref={videoRef} autoPlay loop muted style={{ width: '100%', height: 'auto' }}>
                            <source src={require('../videos/landing.mp4')} type='video/mp4' />
                        </video>
                    </div>
                </div>
              </div>
            </div>
        </section>

        <section className='features-section'>
            <div className='container'>
             <div className='features-head'>
                <p className='features-title'>Explore Our Suite of AI Features</p>
                <p className='feature-subhead'>  Explore AI features that enhance creativity and streamline workflows,all in one place.</p>
             </div>
             <div className='row mt-5'>
                <div className='col-lg-4 px-1'>
                    <div className='features-card mt-2'>
                        <p className='card-head'>Re-Imagine</p>
                        <p className='card-subhead'> Elevate your visuals with AI that turns imagination into stunning reality.</p>
                        <div className='d-flex justify-content-center'>
                        <img src={require('../images/Landing/feature1.png')} className='feature-img1' />
                        </div>
                    </div>
                    <div className='features-card mt-2'>    
                        <p className='card-head'>Super Resolution</p>
                        <p className='card-subhead'> Upscale your images by 2x or 4x without losing detail.</p>
                        <div className='d-flex justify-content-center'>
                        <img src={require('../images/Landing/feature2.png')} className='feature-img1-2' />
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 px-1 mt-2'>
                    <div className='features-card'>
                            <p className='card-head'>Text To Image</p>
                            <p className='card-subhead'>  Turn words into visuals with our AI detailed images from simple text descriptions instantly.</p>
                            <div className='d-flex justify-content-center'>
                            <img src={require('../images/Landing/feature3.png')} className='feature-img2' />
                            </div>
                    </div>
                    <div className='features-card mt-2'>
                            <p className='card-head'> Realistic images</p>
                            <p className='card-subhead'> Experience lifelike AI images for your projects.</p>
                            <div className='d-flex justify-content-center'>
                            <img src={require('../images/Landing/feature4.png')} className='feature-img2-2' />
                            </div>
                    </div>
                </div>
                <div className='col-lg-4 px-1 mt-2'>
                    <div className='features-card'>
                            <p className='card-head'> High-Quality Image</p>
                            <p className='card-subhead'> Create images from textual descriptions with high resolution and attention to detail.</p>
                            <div className='d-flex justify-content-center'>
                            <img src={require('../images/Landing/feature5.png')} className='feature-img3' />
                            </div>
                    </div>
                    <div className='features-card mt-2'>
                        <p className='card-head'>Super Resolution</p>
                        <p className='card-subhead'> Upscale your images by 2x or 4x without losing detail.</p>
                        <div className='d-flex justify-content-center'>
                        <img src={require('../images/Landing/feature6.png')} className='feature-img3-2' />
                        </div>
                    </div>
                </div>
             </div>
             <div className='features-bottom'>
                <Link to="/imagecreation">
                    <div className='landing-button'>
                            <span className='landing-getstart'>Go To Creations</span>
                            <i class="bi bi-arrow-right landing-arrow"></i>
                    </div>
                </Link>
             </div>
            </div>
        </section>

        <section className='newai-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 mx-auto d-flex justify-content-center'>
                        <div className='newai-left'></div>
                    </div>
                    <div className='col-lg-6 '>
                        <div className='newai-rightone'>
                            <p className='why-choose'> Why Choose NewAI</p>
                            <p className='whychoose-header'> Empowering Creativity, Pixel by Pixel</p>
                            <p className='whychoose-subhead'> we empower your creativity, pixel by pixel. Join us to unlock your artistic potential.</p>
                        </div>
                        <div className='newai-righttwo'></div>
                    </div>
                </div>
            </div>
        </section>

        <section className='FAQ-section'>
            <div className='container'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-lg-8'>
                        <div className='FAQ-card'>
                            <p className='FAQ-subhead'> Frequently Asked Questions</p>
                            <p className='FAQ-head'> Find Answers Here</p>
                            <div className='FAQ-questions'>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    >
                                     How does the AI image generation process work?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    GANs and VAEs learn from datasets to create new images: GANs compete to generate realistic images, while VAEs encode and decode to produce new ones.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    >
                                    What types of images can the AI generate?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    AI can generate diverse images spanning from realistic faces to landscapes, animals, abstract art, architecture, medical imagery, and beyond, showcasing versatility across various domains.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                    >
                                      Are the generated images royalty-free for commercial use?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    The legality of commercial use for generated images depends on factors like dataset rights and model licensing terms, requiring careful review for clarity.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    >
                                     Can I customize the style or appearance of the generated images?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    Customizing the style or appearance of generated images is possible through techniques like fine-tuning models, adjusting input parameters, or using specialized models designed for style transfer or specific visual effects.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    >
                                    Is there a limit to the number of images I can generate?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    The number of images you can generate depends on factors like computational resources, dataset size, and model complexity, but there's typically no hard limit. However, practical constraints may arise due to hardware limitations or time constraints for training and generating images.
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='footer-section'>
            <div className='container'>
                <div className='footer-card'>
                    <p className='footer-content'> © 2024 Company Name. All Rights Reserved. Terms of Service .</p>
                </div>
            </div>
        </section>

{/* 
        <section className='loginhead'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                    <div className='logintext'>
                        <img src={require('../images/ailogo.png')} className='loginimg mb-4'/>
                        <h2 className='mb-4'>Welcome to Generative AI </h2>
                        <h2 className='mb-3'>Log in with you OpenAI account to continue</h2>
                        <div className='loginbutton mt-3'>
                            <Link to="/login"><button className='homelogin'>Log in</button></Link>
                            <Link to="/signup"><button className='homesignup'>Sign up</button></Link>
                        </div>
                    </div>
                    </div>
                </div>
             
            </div>
        </section> */}


    </div>
  )
}

export default Home ;
