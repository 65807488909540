import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link,useNavigate } from "react-router-dom";
import Header from "./Header";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import axios from "axios";
import { toast } from "react-toastify";
import { env } from "../envconfig";

function Forget() {

  const BACKEND_URL = env.BACKEND_URL;
  const navigate = useNavigate();

    const initialFormValue = {
        email: "",
      };

      const initialFormValue1 = {
        password: "",
        confirmPassword: "",
      };

      const [emailValidate, setemailValidate, emailValidateref] = useState(false);
      const [validationnErr, setvalidationnErr] = useState("");
      const [formValue, setFormValue] = useState(initialFormValue);
      const [activeStatus, seractiveStatus] = useState(false);

      const [formValue1, setFormValue1] = useState(initialFormValue1);
      const [passwordValidate, setpasswordValidate,passwordValidateref] = useState(false);
      const [confirmPasswordValidate, setconfirmPasswordValidate,confirmPasswordValidateref] = useState(false);
      const [inputType, setInputType] = useState("password");
      const [passHide, setPasshide] = useState(false);
      const [confinputType, seconftInputType] = useState("password");
      const [confpassHide, setconfPasshide] = useState(false);
      const [validationnErr1, setvalidationnErr1] = useState("");
      const [dpliutl, setdpliutl] = useState("");
      const [isLoading,setIsLoading] = useState(false);

      const { email } = formValue;
      const { password, confirmPassword } = formValue1;

      useEffect(() => {
        linkverify();
      }, [0]);

      const linkverify = async (values) => {
        var dpliutl = window.location.href.split("_")[1];
        if (dpliutl) {
          setdpliutl(dpliutl);
          var obj = {
            link: dpliutl,
          };
          setIsLoading(true);
         const response = await axios.post(`${BACKEND_URL}/user/verifyForgotpasslink`,obj);
         setIsLoading(false);
          if (response.data.status == true) {
            seractiveStatus(true);
            toast.success(response.data.Message);
          } else {
            toast.error(response.data.Message);
          }
        } else {
        }
      };

      const handleChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
        validate(formData);
      };
      const handleChange1 = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData1 = { ...formValue1, ...{ [name]: value } };
        setFormValue1(formData1);
        validate1(formData1);
      };

      const validate = async (values) => {
        const errors = {};
        if (!values.email) {
          errors.email = "Email is a required field";
          setemailValidate(true);
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = "Invalid email address";
          setemailValidate(true);
        } else {
          setemailValidate(false);
        }
    
        setvalidationnErr(errors);
        return errors;
      };

      const validate1 = async (values) => {
        const errors1 = {};
    
        if (!values.password) {
          errors1.password = "Password is a required field";
          setpasswordValidate(true);
        } else if (values.password.length < 5 || values.password.length > 21) {
          setpasswordValidate(true);
          errors1.password = "Password should not below 5 above 21 letters";
        } else if (!values.password.match(/[a-z]/g)) {
          setpasswordValidate(true);
          errors1.password = "Please enter at least One lower character";
        } else if (!values.password.match(/[A-Z]/g)) {
          setpasswordValidate(true);
          errors1.password = "Please enter at least One upper character";
        } else if (!values.password.match(/[0-9]/g)) {
          setpasswordValidate(true);
          errors1.password = "Please enter at One digit character";
        } else {
          setpasswordValidate(false);
        }
    
        if (!values.confirmPassword) {
          errors1.confirmPassword = "Confirm password is a required field";
          setconfirmPasswordValidate(true);
        }  else if (
            values.password &&
            values.confirmPassword &&
            values.password !== values.confirmPassword
          ) {
            errors1.confirmPassword = "Password and confirm password does not match";
            setconfirmPasswordValidate(true);
          }else{
          setconfirmPasswordValidate(false);
        }
    
        setvalidationnErr1(errors1);
        return errors1;
      };

      const formSubmit = async () => {
        try {
          validate(formValue);
          if(formValue.email != "" && emailValidateref.current == false){
            var obj = {
              email: formValue.email,
            };
            setIsLoading(true);
            const response = await axios.post(`${BACKEND_URL}/user/changepswdlink`,obj);
            setIsLoading(false);
            if(response.data.status == true){
              toast.success(response.data.message);
            }else{
              toast.error(response.data.Message);
            }
          }
        } catch (error) {
          // console.log(error,"error");
        }
       
      };

      const passwordHide = (data) => {
        if (data == "hide") {
          setPasshide(true);
          setInputType("text");
        } else {
          setPasshide(false);
          setInputType("password");
        }
      };
      const confpasswordHide = (data) => {
        if (data == "hide") {
          setconfPasshide(true);
          seconftInputType("text");
        } else {
          setconfPasshide(false);
          seconftInputType("password");
        }
      };

      const formSubmitchange = async () => {
        validate1(formValue1);
        if(
          passwordValidateref.current === false && 
          confirmPasswordValidateref.current === false 
        ){
          var obj = {
            password : formValue1.password,
            confimPassword : formValue1.confirmPassword,
            id : dpliutl,
          }
          const response = await axios.post(`${BACKEND_URL}/user/resetpassword`,obj);
          if(response.data.status == true){
            toast.success(response.data.Message);
            navigate("/login");
          }else {
            toast.error(response.data.Message);
          }
        }
      };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6">
            <Header />
            <div className="row forgetrow">
              <div className="col-lg-8">
                <div className="login-card my-5">
                    { activeStatus == false ? (
                          <div className="login-innercard">
                          <span class="Lets-get-started">Forget Password</span>
                         
                          <div className="login-email">
                            <span className="login-label"> E-mail </span>
                            <input
                              type="text"
                              placeholder="Enter your email"
                              className="login-emailinput"
                              name="email"
                              value={email}
                              onChange={handleChange}
                            ></input>
                              {emailValidateref.current == true ? (
                                    <p className="text-danger error-css">
                                      {" "}
                                      {validationnErr.email}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                          </div>
                       
                          <div className="login-btndiv">
                            {isLoading == true ? (
                            <button className="login-button">Loading</button>
                            ):(
                            <button className="login-button" onClick={formSubmit}>Continue</button>
                            )}
                          </div>
                        </div>
                    ) : (
                        <div className="login-innercard">
                        <span class="Lets-get-started">Reset Password</span>
                       
                        <div className="login-password">
                      <span className="login-label">Enter New Password</span>
                      <div className="flex_input_posion">
                        <input
                           type={inputType}
                          placeholder="Enter your password"
                          name="password"
                          className="login-passwordinputfield"
                          value={password}
                          onChange={handleChange1}
                        ></input>
                         {passHide == false ? (
                            <i
                              className="bi bi-eye-slash-fill eye-color"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye-fill eye-color"
                              onClick={() => passwordHide("show")}
                            ></i>
                          )}

                      </div>
                      {passwordValidate == true ? (
                            <p className="text-danger error-css">
                              {" "}
                              {validationnErr1.password}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                    </div>

                    <div className="login-password">
                      <span className="login-label">Confirm New Password</span>
                      <div className="flex_input_posion">
                        <input
                         type={confinputType}
                          placeholder="Re-enter your new password"
                          name="confirmPassword"
                          className="login-passwordinputfield"
                          value={confirmPassword}
                          onChange={handleChange1}
                        ></input>
                        {confpassHide == false ? (
                            <i
                              className="bi bi-eye-slash-fill eye-color"
                              onClick={() => confpasswordHide("hide")}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye-fill eye-color"
                              onClick={() => confpasswordHide("show")}
                            ></i>
                          )}
                      </div>
                      {confirmPasswordValidate == true ? (
                            <p className="text-danger error-css">
                              {" "}
                              {validationnErr1.confirmPassword}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                    </div>
                     
                        <div className="login-btndiv">
                          {isLoading == true ? (
                            <button className="login-button"> Loading </button>
                          ):(
                            <button className="login-button" onClick={formSubmitchange}>Reset Password</button>
                          )}
                         
                        </div>
                      </div>
                    )}
                
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 slider-main">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="sliderone">
                  <div className="relative">
                    <div className="slider-rectangle">
                      <div className="slider-items">
                        <img
                          src={require("../images/Ellipse 1.png")}
                          className="slider-image"
                        ></img>
                        <p className="slider-content">
                          {" "}
                          “A surreal landscape with a mirror-like lake, vibrant
                          lotus flowers, fantastical trees, and mist-covered
                          mountains under a swirling, colorful sky.”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slidertwo">
                  <div className="relative">
                    <div className="slider-rectangle">
                      <div className="slider-items">
                        <img
                          src={require("../images/Ellipse 2.png")}
                          className="slider-image"
                        ></img>
                        <p className="slider-content">
                          “Create an image of a beautiful orange cat which is
                          sitting on a hill with a golden sun kissing face and
                          with a beautiful, stunning background.”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="sliderthree">
                  <div className="relative">
                    <div className="slider-rectangle">
                      <div className="slider-items">
                        <img
                          src={require("../images/Ellipse 3.png")}
                          className="slider-image"
                        ></img>
                        <p className="slider-content">
                          “Generate an image of a african kid with a authentic
                          dress standing in a paddy field on a evening time.
                          make the light golden”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="sliderfour">
                  <div className="relative">
                    <div className="slider-rectangle">
                      <div className="slider-items">
                        <img
                          src={require("../images/Ellipse 4.png")}
                          className="slider-image"
                        ></img>
                        <p className="slider-content">
                          “A surreal landscape with a mirror-like lake, vibrant
                          lotus flowers, fantastical trees, and mist-covered
                          mountains under a swirling, colorful sky.”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forget;
